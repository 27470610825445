<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.landingPageInfo.label_page_title }}</h1>
      <BannerAid :bannerLink="`store`" />
      <app-carousel-slider v-if="allowPage && landingPageInfo.rotatorImages.length > 0" :slides="preppedSlides">
      </app-carousel-slider>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <b-container class="top">
        <b-row class="top-row">
          <b-col sm="12" xs="12" class="top-col mb-5">
            <top-nav-search class="top-nav-search" :showDescriptionText="true" :showMerchTotal="showMerchTotal"
              :i18n="translations.components">
            </top-nav-search>
          </b-col>
        </b-row>
        <b-row class="top-row">
          <b-col sm="12" xs="12" class="top-col mb-5">
            <div class="switch-buttons mt-3">
              <b-button @click="handleMerchToggle" variant="secondary " class="mr-3 merchscriptBtn"
                :class="{ merchscriptBtnSelected: this.showMerchandiseScripture === 'Merchandise' }">
                {{ this.landingPageInfo.buttonMerchandise }}
              </b-button>
              <b-button v-if="user_accessible" @click="handleScriptureToggle" variant="secondary" class="merchscriptBtn"
                :class="{ merchscriptBtnSelected: this.showMerchandiseScripture === 'Scripture' }">
                {{ this.landingPageInfo.buttonScripture }}
              </b-button>

              <camp-selector @camp_changed="page_load($event)" :onlyUSA="onlyUSA"
                :tooltip="translations.components['camp-select'].tcTooltip" class="mt-3 mb-1"
                v-if="showMerchandiseScripture === 'Scripture'" :i18n="translations.components"></camp-selector>
            </div>
          </b-col>
        </b-row>
        <b-row class="top-row" v-if="showMerchandiseScripture === 'Scripture'">
          <b-col sm="12" xs="12" class="top-col mb-5">
            <h5 class="fundbalance">
              {{ this.landingPageInfo.Label_CampFundBalance }}
              {{
                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    this.landingPageInfo.CampScriptureFundBalance
                  )
              }}
            </h5>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="tiles">
        <b-row class="tile-row" v-if="showMerchandiseScripture === 'Merchandise'">
          <b-col sm="4" xs="12" class="tile-col mb-5" v-for="(obj, index) in merchandise.Subcategories" :key="index">
            <card :category="`Merchandise`" :itemName="obj.SubcategoryName" :itemId="obj.SubcategoryId"
              :itemDescription="obj.Description" :btnText="obj.ButtonViewMore" :className="obj.SubcategoryClass"
              @set_item="setSubcategoryAndRedirect($event)">
              <component class="icon" :is="obj.SubcategoryIconName"></component>
            </card>
          </b-col>
        </b-row>
        <b-row class="tile-row" v-if="showMerchandiseScripture === 'Scripture'">
          <b-col sm="4" xs="12" class="tile-col mb-5" v-for="(obj, index) in scripture.Subcategories" :key="index">
            <card :category="`Scripture`" :itemName="obj.SubcategoryName" :itemId="obj.SubcategoryId"
              :itemDescription="obj.Description" :btnText="obj.ButtonViewMore" :className="obj.SubcategoryClass"
              @set_item="setSubcategoryAndRedirect($event)">
              <component class="icon" :is="obj.SubcategoryIconName"></component>
            </card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import Administrative_Materials_Icon_blue from '@/assets/svgs/gideon-store/Administrative_Materials_Icon_blue.vue'
import Apparel_Icon_Blue from '@/assets/svgs/gideon-store/Apparel_Icon_Blue.vue'
import Aux_Member_Icon_Blue from '@/assets/svgs/gideon-store/Aux_Member_Icon_Blue.vue'
import BannerAid from '@/components/BannerAid.vue'
import Business_Card_Icon_Blue from '@/assets/svgs/gideon-store/Business_Card_Icon_Blue.vue'
import CampSelect from '@/components/CampSelect.vue'
import Card from './CardStoreLp.vue'
import Carousel from '@/components/CarouselSliderStore.vue'
import Christmas_Card_Icon_Blue from '@/assets/svgs/gideon-store/Christmas_Card_Icon_Blue.vue'
import ChurchContacts from '@/assets/svgs/church-contacts.vue'
import ChurchMembersFriends from '@/assets/svgs/church-members-friends.vue'
import church_details from '@/assets/svgs/church-details.vue'
import Church_Icon_Blue from '@/assets/svgs/gideon-store/Church_Icon_Blue.vue'
import contact_information from '@/assets/svgs/contact-information.vue'
import Faith_Fund_Icon_Blue from '@/assets/svgs/gideon-store/Faith_Fund_Icon_Blue.vue'
import Gideon_Card_Icon_Blue from '@/assets/svgs/gideon-store/Gideon_Card_Icon_Blue.vue'
import Membership_Icon_Blue from '@/assets/svgs/gideon-store/Membership_Icon_Blue.vue'
import Prayer_Icon_Blue from '@/assets/svgs/gideon-store/Prayer_Icon_Blue.vue'
import presentations_service_times from '@/assets/svgs/presentations-service-times.vue'
import Promotion_Icon_Blue from '@/assets/svgs/gideon-store/Promotion_Icon_Blue.vue'
import PWT_Icon_Yellow from '@/assets/svgs/gideon-store/PWT_Icon_Yellow.vue'
import Scripture_Distribution_Icon_Blue from '@/assets/svgs/gideon-store/Scripture_Distribution_Icon_Blue.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'
import Special_Scriptures_Icon_Blue from '@/assets/svgs/gideon-store/Special_Scriptures_Icon_Blue.vue'
import Table_Runner_Icon_Blue from '@/assets/svgs/gideon-store/Table_Runner_Icon_Blue.vue'
import TopNavSearch from './TopNavSearch.vue'
import Visits from '@/assets/svgs/visits.vue'

export default {
  name: 'store',
  mixins: [securityMixin, translationMixin],
  data() {
    return {
      categoryId: {
        merchandise: 'd70f0610-8acd-4335-85f1-ff1d4a55b66a',
        scripture: '84ab8469-e46a-4d79-9745-35726439396e',
      },
      translations: {},
      user_accessible: false,
      storeLPPayload: {
        lang: 'en',
        landingpage: 'Store_Landing',
      },
      storeNavPagePayload: {
        lang: 'en',
        landingpage: 'Store_Navigation',
      },
      showMerchandiseScripture: 'Merchandise',
      secured_store_view_controls: {
        store_scripture_button: 'dd37ad10-32f0-4607-833f-8b6c41d22bc0',
        store_merchandise_button: 'ec511c9b-8a0c-49b4-91f4-0d2622b3147e',
      },
      onlyUSA: true,
    }
  },
  components: {
    Administrative_Materials_Icon_blue: Administrative_Materials_Icon_blue,
    appCarouselSlider: Carousel,
    Apparel_Icon_Blue: Apparel_Icon_Blue,
    Aux_Member_Icon_Blue: Aux_Member_Icon_Blue,
    BannerAid: BannerAid,
    Business_Card_Icon_Blue: Business_Card_Icon_Blue,
    campSelector: CampSelect,
    card: Card,
    church_details: church_details,
    ChurchContacts: ChurchContacts,
    Church_Icon_Blue: Church_Icon_Blue,
    Christmas_Card_Icon_Blue: Christmas_Card_Icon_Blue,
    ChurchMembersFriends: ChurchMembersFriends,
    contact_information: contact_information,
    Faith_Fund_Icon_Blue: Faith_Fund_Icon_Blue,
    Gideon_Card_Icon_Blue: Gideon_Card_Icon_Blue,
    Membership_Icon_Blue: Membership_Icon_Blue,
    presentations_service_times: presentations_service_times,
    Prayer_Icon_Blue: Prayer_Icon_Blue,
    Promotion_Icon_Blue: Promotion_Icon_Blue,
    PWT_Icon_Yellow: PWT_Icon_Yellow,
    Scripture_Distribution_Icon_Blue: Scripture_Distribution_Icon_Blue,
    securityBanner: SecurityBanner,
    Special_Scriptures_Icon_Blue: Special_Scriptures_Icon_Blue,
    Table_Runner_Icon_Blue: Table_Runner_Icon_Blue,
    topNavSearch: TopNavSearch,
    Visits: Visits,
  },
  methods: {
    ...mapActions({
      getControlPermissions: 'user/getControlPermissions',
      loadStoreLandingPage: 'gideonStore/loadStoreLandingPage',
      loadStoreNavigationPage: 'gideonStore/loadStoreNavigationPage',
      processPreauthNF: 'gideonStore/processPreauthNF',
      setLoadingStatus: 'menu/setLoadingStatus',
      setStoreSelectedCategory: 'gideonStore/setStoreSelectedCategory',
      setStoreSelectedSubCategoryId: 'gideonStore/setStoreSelectedSubCategoryId',
    }),
    setSubcategoryAndRedirect(data) {
      let extUrl = this.hasExternalUrl(data)
      if (extUrl) {
        window.open(extUrl, '_blank')
      } else {
        this.setStoreSelectedSubCategoryId(data.itemId)
        this.$router.push({ name: 'store-category-products' })
      }
    },
    handleMerchToggle() {
      this.showMerchandiseScripture = 'Merchandise'
    },
    handleScriptureToggle() {
      this.showMerchandiseScripture = 'Scripture'
    },
    hasExternalUrl(data) {
      let eUrl = ''
      const selectedItem =
        data.category === 'Merchandise'
          ? this.merchandise.Subcategories.find((el) => el.SubcategoryId === data.itemId)
          : this.scripture.Subcategories.find((el) => el.SubcategoryId === data.itemId)
      if (selectedItem && selectedItem.ExternalUrl) {
        eUrl = selectedItem.ExternalUrl.replace('[ind_key]', this.userId)
      }
      return eUrl
    },
    async page_load(evt) {
      if (!evt) {
        this.setLoadingStatus(true)
        if (this.storeLPPayload.cmp_key != this.userCampKey) {
          this.storeNavPagePayload.lang = this.prefCulture
          this.storeLPPayload.lang = this.prefCulture
          this.storeLPPayload.cmp_key = this.userCampKey
          await Promise.all([
            await this.checkPermissions(),
            await this.loadStoreLandingPage(this.storeLPPayload),
            await this.loadStoreNavigationPage(this.storeNavPagePayload),
          ])
        }
        this.setLoadingStatus(false)
      }
    },
    async checkPermissions() {
      await this.getControlPermissions(this.secured_store_view_controls.store_scripture_button).then(() => {
        this.user_accessible = this.determineAccessibility(this.secured_store_view_controls.store_scripture_button)
      })
    },
  },
  computed: {
    ...mapGetters({
      categories: 'gideonStore/gideonStoreCategories',
      demograph: 'user/demograph',
      gideonStoreSelectedCategory: 'gideonStore/gideonStoreSelectedCategory',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      landingPageInfo: 'gideonStore/gideonStoreLandingPageInfo',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userLogin: 'user/userLogin',
      userId: 'user/userId',
    }),
    allowPage() {
      return true
      // return this.isInMenu(this.$route.path)
    },
    merchandise() {
      let merchandise = []
      const merchandiseCategoryGuid = this.categoryId.merchandise
      merchandise = this.categories.filter((c) => c.CategoryId === merchandiseCategoryGuid)
      return merchandise.length > 0 ? merchandise[0] : merchandise
    },
    scripture() {
      let scripture = []
      const scriptureCategoryGuid = this.categoryId.scripture
      scripture = this.categories.filter((c) => c.CategoryId === scriptureCategoryGuid)
      return scripture.length > 0 ? scripture[0] : scripture
    },
    preppedSlides() {
      const ps = this.landingPageInfo.rotatorImages.map((p, index) => {
        return {
          id: index,
          src: p.Location,
          title: p.Name,
          link: p.Redirect,
        }
      })
      return ps
    },
    showMerchTotal() {
      return this.demograph.camp_key === this.userCampKey
    },
  },
  async created() {
    this.processPreauthNF()
    await Promise.all([this.getComponentTranslations('camp-select', 'security-banner', 'store-topnav-search')]).then(
      (results) => {
        const componentTranslations = results[0]
        this.$set(this.translations, 'components', componentTranslations)
      }
    )
    await this.page_load().then(() => {
      if (['Merchandise', 'Scripture'].includes(this.gideonStoreSelectedCategory)) {
        this.showMerchandiseScripture = this.gideonStoreSelectedCategory
      }
    })
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.fundbalance {
  text-align: center;
}

.page-header {
  p {
    text-align: center;
    margin-top: 20px;
  }
}

.top-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  .top-col {
    .top-nav-search {
      width: 100%;
    }
  }
}

.text-1 {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.text-2 {
  text-align: center;
  color: $secondary-color;
  width: 70%;
  margin: 0 auto;
}

.switch-buttons {
  margin: 0 auto;
  text-align: center;

  .merchscriptBtn {
    background-color: #747474;
  }

  .merchscriptBtnSelected {
    background-color: $secondary-color;
  }
}

.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;

  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;

    .tile-col-card {
      flex-grow: 2;

      .icon {
        min-height: 75px;
      }
    }
  }
}

.tiles {
  margin-bottom: 80px;
}
</style>
